<template>
  <v-container v-if="location">
    <template v-if="$attrs.tripDay.showImages == true">
      <v-card >
        <v-img
          cover
          v-for="(image, iindex) in location.images"
          :key="iindex"
          :src="image.fileUrl"
          max-height="300"
        ></v-img>
      </v-card>
    </template>
    <template v-else>
      <h5>{{ location.name }}</h5>
      <!-- <p>{{ $attrs.tripDay.showImages }}</p> -->
      <small class="description"
        >{{ location.gpsfield.latitude }},
        {{ location.gpsfield.latitude }}</small
      >
    </template>

    <!-- <v-list-item class="pl-0">
      <template v-slot:default="{ active }">
        <v-icon size="20" class="handle">mdi-drag-vertical</v-icon>
        <h5>{{$attrs.location.name}}</h5>
        <v-list-item-content>
          <v-list-item-title
            class="font-weight-bold"
            v-if="location.type == 'day'"
            v-text="'Day ' + location.title"
          ></v-list-item-title>
          <v-list-item-title v-else v-text="location.name"></v-list-item-title>
          <v-list-item-subtitle
            v-if="location.type == 'location'"
            class="text--primary"
            v-text="location.subtitle"
          ></v-list-item-subtitle>

          <v-list-item-subtitle
            v-text="location.subtitle"
            v-if="location.type == 'location'"
          ></v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-action v-if="location.type == 'location'">
          <v-list-item-action-text
            v-text="location.action"
          ></v-list-item-action-text>
          <v-menu rounded="b-xl" offset-y>
            <template v-slot:activator="{ attrs, on }">
              <v-icon color="black" v-bind="attrs" v-on="on">
                mdi-dots-horizontal
              </v-icon>
            </template>

            <v-list>
              <v-list-item v-for="(act, aindex) in actions" :key="aindex" link>
                <v-list-item-title v-text="act.name"  @click="_selectAction(item)"></v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-list-item-action>
      </template>
    </v-list-item> -->
    <v-divider></v-divider>
  </v-container>
</template>
<script>

export default {
  data() {
    return {
      actions: [
        { name: "Xасаx", actionType: "remove" },
        { name: "Дээш шилжүүлэx", actionType: "movetotop" },
      ],
    };
  },
  created(){
    console.log(this.location)
  },
  methods: {

    _selectAction(item) {
      console.log(item);
      var i = 0;
      for (var location of this.$attrs.tripDay.locations) {
        if (tripDay.id == location.id) {
          this.$attrs.tripDay.locations.splice(i, 1);
          break;
        }
        i++;
      }
    },
  },
  props: { location: Object },
};
</script>